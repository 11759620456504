import { Box, HStack, VStack, Text, Image } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../.tina/__generated__/types";

interface TestimonialProps {
  name?: Maybe<string>;
  backgroundColor?: Maybe<string>;
  logo?: Maybe<string>;
  text?: Maybe<string>;
  location?: Maybe<string>;
}

function Testimonial({
  name,
  backgroundColor,
  logo,
  text,
  location,
}: TestimonialProps) {
  return (
    <VStack
      backgroundColor={backgroundColor ?? undefined}
      p={{ base: 4, lg: 6 }}
      spacing="4"
      maxW="md"
      w="100%"
      color="black"
    >
      <HStack w="100%" spacing="4">
        <Image src={logo ?? ""} height="75px" alt={name ?? "company logo"} />
        <VStack alignItems="flex-start" spacing="0">
          <Text fontSize="md">{name}</Text>
          <Text fontSize="xs" color="#53505a">
            {location}
          </Text>
        </VStack>
      </HStack>
      <Box>
        <Text fontSize="sm">{text}</Text>
      </Box>
    </VStack>
  );
}

export default Testimonial;
