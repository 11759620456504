import { Flex } from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import React from "react";
import { TinaMarkdown, TinaMarkdownContent } from "tinacms/dist/rich-text";
import { Maybe } from "../../.tina/__generated__/types";

interface TextBlockProps {
  data: {
    body?: Maybe<string> | TinaMarkdownContent;
  };
}

function TextBlock({ data: { body } }: TextBlockProps) {
  return (
    <Flex my="100px" width="100%" justify="center" paddingX="12%">
      {/* This tag renders the rich text properties of the markdown content */}
      <Prose>
        <TinaMarkdown content={body as TinaMarkdownContent} />
      </Prose>
    </Flex>
  );
}

export default TextBlock;
