import { Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";

function OurProcessItem({ text }: { text: Maybe<string> }) {
  return (
    <Flex backgroundColor="#31FFD4" direction="row" padding="5">
      <Heading fontSize={{ base: "16px", md: "20px", lg: "25px" }}>
        {text}
      </Heading>
    </Flex>
  );
}

export default OurProcessItem;
