import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { Maybe } from "../../.tina/__generated__/types";

interface BannerProps {
  data: {
    items?:
      | Maybe<
          Maybe<{
            largeText?: Maybe<string> | undefined;
            smallText?: Maybe<string> | undefined;
          }>[]
        >
      | undefined;
  };
}

function Banner({ data: { items } }: BannerProps) {
  return (
    <Stack
      justifyContent="space-between"
      direction={{ base: "column", lg: "row" }}
      bg="yellow.500"
      divider={
        <>
          <Box display={{ base: "none", lg: "initial" }}>
            <Flex px="8" h="100%" minH="5.813rem">
              <Box w="0.125rem" h="100%" bgColor="black" />
            </Flex>
          </Box>
          <Box>
            <Box h="0.125rem" bgColor="black" my="2" />
          </Box>
        </>
      }
      w="100%"
      spacing={{ base: "4", xl: "0" }}
      px={{ base: "7%", xl: "0" }}
      py={{ base: "2", sm: "4", md: "6", lg: 0 }}
    >
      {items?.map((data, i) => (
        <Stack
          key={`${data?.smallText}${data?.largeText}${i}`}
          maxW={{ xl: "calc(100vw/3)" }}
          w="100%"
          flex="1 1 0px"
          flexDirection={{
            base: "column-reverse",
            xl: "row",
          }}
          alignItems={{ base: "left", xl: "center" }}
          justifyContent="center"
        >
          <Text fontSize="5xl" fontWeight="medium" textStyle="bold">
            {data?.largeText}
          </Text>
          <Text
            fontSize="sm"
            paddingLeft={{ base: "0", xl: "8" }}
            maxH="2.5rem"
            textStyle="mono"
            isTruncated
            whiteSpace="pre-wrap"
            width={{ base: "unset", xl: "calc(100vw/6)" }}
          >
            {data?.smallText}
          </Text>
        </Stack>
      ))}
    </Stack>
  );
}

export default Banner;
