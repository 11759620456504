import { getStaticPropsForTina, staticRequest } from "tinacms";

import type { PagesDocument } from "../.tina/__generated__/types";
import { Blocks } from "../components/Blocks";
import { layoutQueryFragment } from "../components/layout/Layout";

export default function HomePage(
  props: AsyncReturnType<typeof getStaticProps>["props"]
) {
  return <Blocks {...props?.data?.getPagesDocument?.data} />;
}

export const getStaticProps = async ({ params }: any) => {
  const tinaProps = (await getStaticPropsForTina({
    query: `#graphql
      query ContentQuery($relativePath: String!) {
        # "index.md" is _relative_ to the "Pages" path property in your schema definition
        # you can inspect this file at "content/pages/index.md"
        ${layoutQueryFragment}
        getPagesDocument(relativePath: $relativePath) {
          data {
            __typename
            title
            description
            blocks {
              __typename
              ... on PagesBlocksHeadline {
                line1
                line2
                subtitle
              }
              ... on PagesBlocksBanner {
                items {
                  smallText
                  largeText
                }
              }
              ... on PagesBlocksTextAndButton {
                largeText
                smallText
                buttonText
                buttonLink
              }
              ... on PagesBlocksPressLogos {
                items {
                  image
                  link
                  alt
                  width
                  height
                }
                heading
                backgroundColor
                buttonLink
                buttonText
              }
              ... on PagesBlocksTextAndImage {
                  image
                  largeText
                  smallText
                  imageAlt
                  imageSide
                  preHeading
                  buttonText
                  buttonLink
              }
              ... on PagesBlocksContactForm {
                title
              }
              ... on PagesBlocksSolutions {
                solutionsDescription {
                  preHeading
                  headingText
                  descriptionText
                  buttonText
                  buttonLink
                }
                solutionsService1 {
                  imageURL
                  imageAlt
                  descriptionText
                }
                solutionsService2 {
                  imageURL
                  imageAlt
                  descriptionText
                }
              }
              ... on PagesBlocksCollabProjects {
                items {
                  imageURL
                  imageAlt
                  projectName
                  backgroundColour
                }
              }
              ... on PagesBlocksJobPosts {
                title
                items {
                  title
                  preferredSkills
                  backgroundColour
                }
              }
              ... on PagesBlocksKeyWords {
                words
              }
              ... on PagesBlocksMap {
                lat
                lng
                zoom
                popupTitle
                popupContent
              }
              ... on PagesBlocksCaseStudies {
                title
                buttonText
                buttonLink
                caseStudy1 {
                  backgroundColour
                  imgURL
                  imgAlt
                  resultNum
                  resultText
                  linkDestination
                }
                caseStudy2 {
                  backgroundColour
                  imgURL
                  imgAlt
                  resultNum
                  resultText
                  linkDestination
                }
                caseStudy3 {
                  backgroundColour
                  imgURL
                  imgAlt
                  resultNum
                  resultText
                  linkDestination
                }
                caseStudy4 {
                  backgroundColour
                  imgURL
                  imgAlt
                  resultNum
                  resultText
                  linkDestination
                }
              }
              ... on PagesBlocksHeroSection {
                backgroundPattern
                heading
                subHeading
                description
                buttonText
                buttonLink
              }
              ... on PagesBlocksPaginationSection {
                sectionHeading
                items {
                  title
                  description
                  backgroundColour
                  iconImgUrl
                  iconImgAlt
                }
              }
              ... on PagesBlocksOurProcess {
                text {
                  sectionTitle
                  heading
                  description
                  buttonText
                  buttonLink
                }
                processItems
              }
              ... on PagesBlocksUseCases {
                sectionHeading
                sectionText
                buttonText
                buttonLink
                useCaseTileItems {
                  tileHeading
                  tileBgColour
                  tileImgBg
                  tileText
                  tileButtonLink
                }
              }
              ... on PagesBlocksTestimonials {
                heading
                items {
                  backgroundColour
                  logo
                  clientName
                  clientLocation
                  text
                }
              }
              ... on PagesBlocksAboutUsHero {
                backgroundPattern
                heading
                buttonText
                buttonLink
                sections {
                  heading
                  text
                }
                image
                imageAlt
              }
              ... on PagesBlocksQuoteImage {
                image
                imageAlt
                quote
                imageLabel
                imageBackgroundColor
                heading
                mainText
                quote
                quoteBackgroundColor
              }
              ... on PagesBlocksTextBlock {
                body
              }
              ... on PagesBlocksNewsLetterBlock {
                bgColour
              }
            }
          }
        }
      }
  `,
    variables: { relativePath: `${params.filename}.md` },
  })) as { data: { getPagesDocument: PagesDocument } };

  return {
    props: {
      ...tinaProps,
    },
  };
};

export const getStaticPaths = async () => {
  const pagesListData = (await staticRequest({
    query: `#graphql
      {
        getPagesList {
          edges {
            node {
              sys {
                filename
              }
            }
          }
        }
      }
    `,
  })) as any;
  return {
    paths: pagesListData.getPagesList.edges.map((page: any) => ({
      params: { filename: page.node.sys.filename },
    })),
    fallback: false,
  };
};

export type AsyncReturnType<T extends (...args: any) => Promise<any>> =
  T extends (...args: any) => Promise<infer R> ? R : any;
