import { Text, Heading, VStack } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import { textStyles } from "../../../theme/textStyles";
import MonoButton from "../../common/MonoButton";

export interface SolutionsDescriptionProps {
  preHeading?: Maybe<string>;
  headingText?: Maybe<string> | undefined;
  descriptionText?: Maybe<string> | undefined;
  buttonText?: Maybe<string> | undefined;
  buttonLink?: Maybe<string> | undefined;
}

function SolutionsDescription(data: SolutionsDescriptionProps) {
  return (
    <VStack align="flex-start" spacing={{ base: "4", sm: "6" }}>
      <Text
        fontFamily={textStyles.mono.fontFamily}
        fontSize={{ base: "lg", lg: "xl" }}
      >
        {data?.preHeading}
      </Text>
      <Heading as="h2">{data.headingText}</Heading>
      <Text fontSize={{ base: "xl", md: "2xl" }}>{data.descriptionText}</Text>
      <MonoButton text={data.buttonText} link={data.buttonLink} arrow />
    </VStack>
  );
}

export default SolutionsDescription;
