import {
  Container,
  Heading,
  HStack,
  VStack,
  Text,
  Image,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import { Maybe } from "../../.tina/__generated__/types";
import MonoButton from "../common/MonoButton";

interface AboutUsHeroProps {
  data: {
    backgroundPattern?: Maybe<string>;
    heading?: Maybe<string>;
    buttonText?: Maybe<string>;
    buttonLink?: Maybe<string>;
    sections?: Maybe<
      Maybe<{
        heading?: Maybe<string>;
        text?: Maybe<string>;
      }>[]
    >;
    image?: Maybe<string>;
    imageAlt?: Maybe<string>;
  };
}

function AboutUsHero({
  data: {
    heading,
    backgroundPattern,
    sections,
    buttonText,
    buttonLink,
    image,
    imageAlt,
  },
}: AboutUsHeroProps) {
  const containerCss = useBreakpointValue({
    base: css`
      padding: 0;
    `,
    md: css``,
  });

  return (
    <Container css={containerCss}>
      <VStack spacing="10">
        <Stack
          position="relative"
          alignItems="stretch"
          direction={{ base: "column-reverse", md: "row" }}
        >
          <VStack
            w="100%"
            spacing="10"
            alignItems="flex-start"
            maxW={{ base: "container.xl", md: undefined }}
            pt={{ base: "4", md: "200px" }}
            pb="20"
          >
            {sections?.map((section, index) => (
              <VStack
                key={`${section?.heading}-${index}`}
                alignItems="flex-start"
              >
                <Text fontWeight="900">{section?.heading}</Text>
                <Text>{section?.text}</Text>
              </VStack>
            ))}
            <MonoButton text={buttonText} link={buttonLink} arrow />
          </VStack>
          <VStack
            w="100%"
            backgroundImage={`/patterns/${backgroundPattern}.svg`}
            alignItems="flex-start"
            justifyContent="flex-end"
            minHeight={{ md: "580px" }}
            spacing="6"
            pt="14"
          >
            <Heading fontSize="lg" px="6" display={{ md: "none" }}>
              {heading}
            </Heading>

            <Image src={image ?? ""} alt={imageAlt ?? ""} height="400px" />
          </VStack>
        </Stack>
      </VStack>
      <Heading
        position="absolute"
        top="180px"
        display={{ base: "none", md: "initial" }}
        maxW="container.xl"
      >
        {heading}
      </Heading>
    </Container>
  );
}

export default AboutUsHero;
