import { Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../.tina/__generated__/types";
import { textStyles } from "../../theme/textStyles";

export interface KeyWordsProps {
  data: {
    words?: Maybe<Maybe<string>[]> | undefined;
  };
}

function KeyWords({ data: { words } }: KeyWordsProps) {
  return (
    <VStack
      backgroundImage="/patterns/pattern1.svg"
      paddingLeft={{ base: "6", md: "12" }}
      paddingY={{ base: "8" }}
      alignItems="flex-start"
      color="black"
    >
      {words?.map((word) => (
        <Text
          fontSize={{ base: "50", lg: "60", xl: "80" }}
          key={word}
          {...textStyles.bold}
        >
          {word}
        </Text>
      ))}
    </VStack>
  );
}

export default KeyWords;
