import { FormControl, FormLabel, HStack, Text, Heading, VStack, Input, Button, Link,  useToast, Flex} from '@chakra-ui/react';
import axios from 'axios';
import React  from 'react'
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Maybe } from '../../.tina/__generated__/types';
import newsletterFormSchema from '../../consts/newsletterFormSchema';
import { textStyles } from '../../theme/textStyles';
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { UnderlinedSpan } from './NewsLetterForm';

interface NewsLetterFormRemadeProps {
    data: {
          bgColour?: Maybe<string>;
    };
}

const NewsLetterBlock = ( { data: { bgColour } } : NewsLetterFormRemadeProps) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm<z.infer<typeof newsletterFormSchema>>();
    const toast = useToast()
    const onSubmit: SubmitHandler<z.infer<typeof newsletterFormSchema>> = async (data) => {
        try {
            await axios.post("/api/forms/newsletter", data);
            reset()
            toast({
                render: () => (
                    <HStack backgroundColor="yellow" padding="15px" rounded="sm">
                    <CheckCircleIcon padding="10px" w={10} h={10} />
                    <VStack alignItems="flex-start" spacing={0}>
                      <Text {...textStyles.mono}>Thank you for subscribing to our newsletter!</Text>
                    </VStack>
                  </HStack>
                ),
                position: "bottom",
                duration: 2000,
                isClosable: true, 
            })
        } catch {
            toast({
                status: "error",
                duration: 9000,
                isClosable: true,
                render: () => (
                  <HStack backgroundColor="red" padding="15px" rounded="sm">
                    <WarningIcon padding="10px" w={10} h={10} />
                    <VStack alignItems="flex-start" spacing={0}>
                      <Text {...textStyles.mono}>Something Went Wrong.</Text>
                      <Text>Please try again later.</Text>
                    </VStack>
                  </HStack>
                ),
              });
        }
    }

    return (
        <Flex w="100vw" justifyContent="center" py="5%">
            <VStack spacing={10} padding="1em" bgColor={bgColour as string}
            m={{ base: "10px", md: "none" }}
            maxW="container.md" textAlign="center" alignSelf="center">
                <Text textStyle="mono" fontSize="15px">THE LAB LETTER</Text>
                <Heading as="h2" fontSize={{base: "md", lg: "2xl"}}>Subscribe to Our Newsletter</Heading>
                <Text fontSize={{base: "sm", lg: "md"}}>We curate the best blockchain insights to help you shape the new world.</Text>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={10} padding={"0.5em"}>
                        <FormControl w={{base: "100%", lg: "80%"}}>
                            <Input borderRadius="0" color="#000" bg="#fff" type="email" placeholder="Your email" {...register("email", {required: true})} />
                            {errors.email?.type === 'required' && (
                                <Text textStyle="mono" fontSize="12px">Email is required</Text>
                            )}
                        </FormControl>
                        <FormControl>
                            <HStack spacing={5}>
                                <input type="checkbox" {...register("communicationsPolicyAccepted")} />
                                <FormLabel fontSize="12px">Yes, I would like to receive personalised communications
                                regarding relevant content and services in accordance with{" "}
                                <Link href="/privacy-policy">
                                <UnderlinedSpan>
                                    Labrys&apos; Privacy Policy
                                </UnderlinedSpan>
                                </Link>
                                . You may unsubscribe from these communications at any time.</FormLabel>
                            </HStack>
                        </FormControl>
                        <Button bg="#fff" border="1px solid #000" _hover={{bg: "yellow.500", cursor: "pointer"}} type="submit">Subscribe</Button>
                    </VStack>
                </form>
            </VStack>
        </Flex>
    )
}

export default NewsLetterBlock;



