import { Container, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import { textStyles } from "../../../theme/textStyles";
import JobPost, { JobPostProps } from "./JobPost";

export interface JobPostsProps {
  data: {
    items?: Maybe<Maybe<JobPostProps>[]> | undefined;
    title?: Maybe<string> | undefined;
  };
}

function JobPosts({ data: { items, title } }: JobPostsProps) {
  return (
    <Container
      maxW="container.xl"
      marginY="40px"
      padding={{ base: "20px", md: undefined }}
    >
      <Stack>
        <Heading as="h2">{title}</Heading>
        <Stack
          direction={{ base: "column", md: "row" }}
          paddingTop={{ base: "30px", sm: "45px", md: "60px" }}
          spacing={5}
        >
          {items?.map((item) => (
            // eslint-disable-next-line react/jsx-key
            <JobPost {...item} />
          ))}
        </Stack>
      </Stack>
    </Container>
  );
}

export default JobPosts;
