import { Heading, Image, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../.tina/__generated__/types";
import MonoButton from "../common/MonoButton";

export interface CaseStudyProps {
  backgroundColour?: Maybe<string> | undefined;
  imgURL?: Maybe<string> | undefined;
  imgAlt?: Maybe<string> | undefined;
  resultNum?: Maybe<string> | undefined;
  resultText?: Maybe<string> | undefined;
  linkDestination?: Maybe<string> | undefined;
}

function CaseStudy(data: CaseStudyProps) {
  return (
    <HStack
      justify="space-between"
      bgColor={data.backgroundColour ?? undefined}
      p={{ base: 4, lg: 6 }}
      h="3xs"
      color="black"
    >
      <VStack
        justify="space-between"
        alignItems="flex-start"
        h="100%"
        maxW="33%"
      >
        {data.imgURL && (
          <Image
            src={data.imgURL}
            alt={data.imgAlt ?? ""}
            // layout="fill"
            objectFit="contain"
            style={{ maxHeight: "50%" }}
          />
        )}
        <MonoButton
          text="Learn more"
          link={data.linkDestination}
          linkVariant
          arrow
        />
      </VStack>
      <VStack alignItems="flex-end" justifyContent="flex-end" h="100%">
        <Heading textAlign="right" fontSize={{ base: "2xl", lg: "3xl" }}>
          {data.resultNum}
        </Heading>
        <Heading textAlign="right" fontSize={{ base: "lg", lg: "xl" }}>
          {data.resultText}
        </Heading>
      </VStack>
    </HStack>
  );
}

export default CaseStudy;
