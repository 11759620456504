import React from "react";
import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import Link from "next/link";
import { Maybe } from "../../.tina/__generated__/types";
import useBackgroundColor from "../../hooks/useBackgroundColor";

interface TextAndButtonProps {
  data: {
    largeText?: Maybe<string>;
    smallText?: Maybe<string>;
    buttonText?: Maybe<string>;
    buttonLink?: Maybe<string>;
  };
}

function TextAndButton({
  data: { largeText, smallText, buttonLink, buttonText },
}: TextAndButtonProps) {
  const bgColor = useBackgroundColor();

  return (
    <Box bgColor={bgColor}>
      <Container>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: "4", sm: "8", md: "12" }}
          alignItems="center"
        >
          <Heading as="h2">{largeText}</Heading>
          <VStack h="100%" justifyContent="space-between">
            <Text fontSize="md">{smallText}</Text>
            {buttonText && buttonLink && (
              <HStack width="100%" justifyContent="left">
                <Link href={buttonLink ?? ""} passHref>
                  <Button colorScheme="lime" mt="2.5">
                    <Text color="black">{buttonText}</Text>
                  </Button>
                </Link>
              </HStack>
            )}
          </VStack>
        </Stack>
      </Container>
    </Box>
  );
}

export default TextAndButton;
