import { Text, Heading, VStack, Box } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import { textStyles } from "../../../theme/textStyles";
import MonoButton from "../../common/MonoButton";

export interface OurProcessTextProps {
  sectionTitle?: Maybe<string>;
  heading?: Maybe<string>;
  description?: Maybe<string>;
  buttonText?: Maybe<string>;
  buttonLink?: Maybe<string>;
}

function OurProcessText(data: OurProcessTextProps) {
  return (
    <VStack alignItems="flex-start" spacing={{ base: "4", sm: "6" }} w="100%">
      <Text {...textStyles.mono} fontSize={{ base: "lg", lg: "xl" }}>
        {data.sectionTitle}
      </Text>
      <Heading as="h2">{data.heading}</Heading>
      <Text fontSize="md">{data.description}</Text>
      <Box display={{ base: "none", lg: "initial" }}>
        <MonoButton text={data?.buttonText} link={data?.buttonLink} arrow />
      </Box>
    </VStack>
  );
}

export default OurProcessText;
