import { Text, VStack, Image } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import { textStyles } from "../../../theme/textStyles";

export interface JobPostProps {
  backgroundColour?: Maybe<string> | undefined;
  title?: Maybe<string> | undefined;
  preferredSkills?: Maybe<Maybe<string>[]> | undefined;
}

function JobPost({ backgroundColour, title, preferredSkills }: JobPostProps) {
  return (
    <VStack
      backgroundColor={backgroundColour ?? undefined}
      maxW={{ base: undefined, md: "330px" }}
      w="100%"
      h="400px"
      padding="27px"
      alignItems="flex-start"
    >
      <Image src="/labrys-logo-no-color.svg" width="60px" height="40px" />
      <Text paddingTop="25px" {...textStyles.bold}>
        {title}
      </Text>
      <Text fontWeight="bold" paddingTop="16px">
        Preferred Skills:
      </Text>
      {preferredSkills?.map((skill) => (
        <Text key={skill}>{skill}</Text>
      ))}
    </VStack>
  );
}

export default JobPost;
