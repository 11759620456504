import { HStack, Text } from "@chakra-ui/react";
import { Maybe } from "../../../.tina/__generated__/types";
import { textStyles } from "../../../theme/textStyles";
import Image from "next/image";

export interface CollabProjectProps {
  imageURL?: Maybe<string> | undefined;
  imageAlt?: Maybe<string> | undefined;
  projectName?: Maybe<string> | undefined;
  backgroundColour?: Maybe<string> | undefined;
}

function CollabProject(data: CollabProjectProps) {
  return (
    <HStack
      h={{ base: "4rem", md: "5rem" }}
      bgColor={data.backgroundColour ?? "none"}
      justifyContent="center"
      alignItems="center"
      px={{ base: "4", md: "8" }}
      spacing={{ base: "4", lg: "6" }}
      color="black"
    >
      {data.imageURL && (
        <Image
          height="50px"
          width="50px"
          src={data.imageURL}
          alt={data.imageAlt ?? ""}
          loading="eager"
        />
      )}
      <Text
        fontSize={{ base: "md", md: "lg" }}
        fontFamily={textStyles.mono.fontFamily}
      >
        {data.projectName}
      </Text>
    </HStack>
  );
}

export default CollabProject;
