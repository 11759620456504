import { Box, Container, Heading } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import useBackgroundColor from "../../../hooks/useBackgroundColor";
import Form from "./Form";

interface ContactFormProps {
  data: { title?: Maybe<string> | undefined };
}

function ContactForm({ data: { title } }: ContactFormProps) {
  const bgColor = useBackgroundColor();

  return (
    <Box
      backgroundImage="/patterns/pattern3.svg"
      padding={{ base: "2", sm: "40px", md: "80px" }}
    >
      <Container>
        <Box
          backgroundColor={bgColor}
          padding={{ base: "15px", sm: "15px", md: "60px" }}
          border="1px solid black"
          maxW="900px"
        >
          <Heading mb="30px">{title}</Heading>
          <Form />
        </Box>
      </Container>
    </Box>
  );
}

export default ContactForm;
