import { Button, Text, useColorModeValue } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";
import { VscArrowRight } from "react-icons/vsc";
import { Maybe } from "../../.tina/__generated__/types";

interface MonoButtonProps {
  text: string | React.ReactNode;
  link?: Maybe<string>;
  arrow?: boolean;
  linkVariant?: boolean;
}

function MonoButton({
  text,
  link,
  arrow = false,
  linkVariant = false,
}: MonoButtonProps) {
  const color = useColorModeValue("black", "whiteAlpha.900");

  if (!text || !link) return null;

  return (
    <Link href={link ?? ""} passHref>
      <Button
        variant={linkVariant ? "link" : "outline"}
        pl={linkVariant ? "0" : undefined}
        borderColor={color}
        color={color}
      >
        <Text mr="1em" textStyle="mono">
          {text}
        </Text>
        {arrow && <VscArrowRight size="23px" />}
      </Button>
    </Link>
  );
}

export default MonoButton;
