import { Button, Flex, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { VscArrowRight } from "react-icons/vsc";
import { Maybe } from "../../../.tina/__generated__/types";
import { textStyles } from "../../../theme/textStyles";

export interface UseCaseTileProps {
  tileHeading?: Maybe<string>;
  tileBgColour?: Maybe<string>;
  tileImgBg?: Maybe<string>;
  tileText?: Maybe<string>;
  tileButtonLink?: Maybe<string>;
}

function UseCaseTile(data: UseCaseTileProps) {
  const router = useRouter();
  return (
    <Flex direction="column" mt={{ base: "15px", md: "25px", lg: "15px" }}>
      <Flex
        bgImg={data.tileImgBg ?? undefined}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        direction="column"
        maxW={{ base: "100%", lg: "400px" }}
      >
        <Flex
          width="fit-content"
          height="fit-content"
          padding="25px 35px"
          position="relative"
          top="0"
          left="0"
          bgColor={data.tileBgColour ?? undefined}
        >
          <Text
            fontFamily={textStyles.mono.fontFamily}
            fontWeight="medium"
            fontSize={{ base: "16px", lg: "18px" }}
          >
            {data.tileHeading}
          </Text>
        </Flex>
        <Flex
          mt="200px"
          direction="column"
          bgColor="#EDEDED"
          padding={{ base: "20px", lg: "28px" }}
        >
          <Flex>
            <Text fontSize="md">{data.tileText}</Text>
          </Flex>
          <Flex justify="flex-end">
            {data.tileButtonLink && (
              <Button
                onClick={() =>
                  data.tileButtonLink && router.push(data.tileButtonLink)
                }
              >
                <VscArrowRight size="23px" />
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default UseCaseTile;
