import {
  Flex,
  VStack,
  Text,
  Container,
  Heading,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../.tina/__generated__/types";
import Image from "next/image";
import { textStyles } from "../../theme/textStyles";
import MonoButton from "../common/MonoButton";
interface TextAndImageProps {
  data: {
    largeText?: Maybe<string>;
    smallText?: Maybe<string>;
    image?: Maybe<string>;
    imageAlt?: Maybe<string>;
    imageSide?: Maybe<string>;
    preHeading?: Maybe<string>;
    buttonText?: Maybe<string>;
    buttonLink?: Maybe<string>;
  };
}

function TextAndImage({
  data: {
    largeText,
    smallText,
    image,
    imageAlt,
    imageSide,
    preHeading,
    buttonText,
    buttonLink,
  },
}: TextAndImageProps) {
  return (
    <Container>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        minHeight="xs"
        direction={{
          base: imageSide === "right" ? "column" : "column-reverse",
          md: imageSide === "right" ? "row" : "row-reverse",
        }}
        spacing={{ base: 0, md: "28" }}
      >
        <VStack
          alignItems="flex-start"
          spacing={{ base: "4", sm: "6" }}
          w="100%"
        >
          <Text {...textStyles.mono} fontSize={{ base: "lg", lg: "xl" }}>
            {preHeading}
          </Text>

          <Heading as="h3">{largeText}</Heading>
          <Text fontSize="md">{smallText}</Text>
          <MonoButton text={buttonText} link={buttonLink} arrow />
        </VStack>

        {image && (
          <Flex w="100%">
            <Image
              src={image}
              alt={imageAlt ?? ""}
              objectFit="contain"
              height="600px"
              width="600px"
            />
          </Flex>
        )}
      </Stack>
    </Container>
  );
}

export default TextAndImage;
