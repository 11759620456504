import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import CollabProject, { CollabProjectProps } from "./CollabProject";
import Marquee from "react-fast-marquee";

interface ScrollbarDataProps {
  data?: {
    items?: Maybe<Maybe<CollabProjectProps>[]>;
  };
}

function CollabProjectScrollBar({ data }: ScrollbarDataProps) {
  return (
    <Box>
      <Marquee gradient={false} delay={1}>
        {data?.items?.map((project) => (
          <CollabProject {...project} key={project?.imageURL} />
        ))}
        {data?.items?.map((project) => (
          <CollabProject {...project} key={project?.imageURL} />
        ))}
        {data?.items?.map((project) => (
          <CollabProject {...project} key={project?.imageURL} />
        ))}
      </Marquee>
    </Box>
  );
}

export default CollabProjectScrollBar;
