import {
  Flex,
  Img,
  VStack,
  Text,
  useBreakpoint,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

import { Maybe } from "../../.tina/__generated__/types";
import GoogleMapReact from "google-map-react";
import mapsStyle from "../../consts/mapsStyle";
import { textStyles } from "../../theme/textStyles";

interface MapProps {
  data: {
    lat?: Maybe<string> | undefined;
    lng?: Maybe<string> | undefined;
    zoom?: Maybe<number> | undefined;
    popupTitle?: Maybe<string> | undefined;
    popupContent?: Maybe<string> | undefined;
  };
}

const Marker = ({ children }: { children: any; lat: number; lng: number }) =>
  children;

function Map({
  data: {
    lat = "-27.46015007880676",
    lng = "153.031412164328",
    zoom = 14,
    popupTitle,
    popupContent,
  },
}: MapProps) {
  const popupLeft = useBreakpointValue({ base: "-125px", md: "36px" });

  const textColor = useColorModeValue("white", "black");

  return (
    <Flex justifyContent="center" style={{ height: "600px", width: "100%" }}>
      {lat && lng && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? "",
          }}
          center={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
          zoom={zoom as number}
          options={{
            styles: mapsStyle,
            fullscreenControl: false,
            zoomControl: false,
          }}
        >
          <Marker lat={parseFloat(lat)} lng={parseFloat(lng)}>
            <div
              style={{
                height: "75px",
                width: "75px",
                position: "relative",
                top: "-37.5px",
                left: "-37.5px",
              }}
            >
              <Img src="/icons/mapMarker.svg" />
            </div>

            {(popupTitle || popupContent) && (
              <VStack
                backgroundColor="#30FFA6"
                w="250px"
                position="relative"
                bottom="262px"
                left={popupLeft}
                alignItems="flex-start"
                padding="20px"
                color={textColor}
              >
                <Text {...textStyles.bold} fontSize="xl">
                  {popupTitle}
                </Text>
                <Text fontSize="md" paddingTop="16px">
                  {popupContent}
                </Text>
              </VStack>
            )}
          </Marker>
        </GoogleMapReact>
      )}
    </Flex>
  );
}

export default Map;
