import {
  Container,
  Grid,
  GridItem,
  Heading,
  HStack,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../.tina/__generated__/types";
import MonoButton from "../common/MonoButton";
import CaseStudy, { CaseStudyProps } from "./CaseStudy";

interface CaseStudySectionProps {
  data: {
    title?: Maybe<string> | undefined;
    buttonText?: Maybe<string> | undefined;
    buttonLink?: Maybe<string> | undefined;
    caseStudy1?: Maybe<CaseStudyProps> | undefined;
    caseStudy2?: Maybe<CaseStudyProps> | undefined;
    caseStudy3?: Maybe<CaseStudyProps> | undefined;
    caseStudy4?: Maybe<CaseStudyProps> | undefined;
  };
}

function CaseStudySection({ data }: CaseStudySectionProps) {
  return (
    <Container>
      <VStack spacing="8">
        <HStack w="100%" alignItems="left" justify="space-between">
          <Heading>{data?.title}</Heading>
          <MonoButton text={data.buttonText} link={data.buttonLink} arrow />
        </HStack>
        <Grid
          templateRows={{ base: "repeat(4, 1fr)", lg: "repeat(2, 1fr)" }}
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
          gap={{ base: "4", lg: "0" }}
        >
          <GridItem>
            <CaseStudy {...data.caseStudy1} />
          </GridItem>
          <GridItem>
            <CaseStudy {...data.caseStudy2} />
          </GridItem>
          <GridItem>
            <CaseStudy {...data.caseStudy3} />
          </GridItem>
          <GridItem>
            <CaseStudy {...data.caseStudy4} />
          </GridItem>
        </Grid>
      </VStack>
    </Container>
  );
}

export default CaseStudySection;
