import React from "react";
import type { Pages } from "../.tina/__generated__/types";
import ContactForm from "./contact/ContactForm";
import JobPosts from "./contact/JobPosts";

import TextAndImage from "./contact/TextAndImage";
import Banner from "./home/Banner";
import CaseStudiesSection from "./home/CaseStudiesSection";
import CollabProjectScrollBar from "./home/CollabProject/CollabProjectScrollBar";
import Headline from "./home/Headline";
import KeyWords from "./home/KeyWords";
import PressLogos from "./home/PressLogos";
import SolutionsSection from "./home/Solutions/SolutionsSection";
import Map from "./contact/Map";
import TextAndButton from "./home/TextAndButton";
import HeroSection from "./services/HeroSection/HeroSection";
import PaginationSection from "./services/PaginationSection/PaginationSection";
import OurProcessSection from "./services/OurProcess/OurProcessSection";
import UseCaseSection from "./services/UseCases/UseCaseSection";
import Testimonials from "./home/TestimonialsSection";
import AboutUsHero from "./aboutUs/AboutUsHero";
import QuoteImage from "./aboutUs/QuoteImage";
import TextBlock from "./common/TextBlock";
import NewsLetterBlock from "./newsletter/NewsLetterBlock";

/**
 * When creating a new block section, add it to the switch statement
 * so that it can be rendered.
 * @param props the Pages of the site
 * @returns a react component corresponding to the desired block.
 */
export const Blocks = (props: Pages) => {
  return (
    <>
      {props.blocks
        ? props.blocks.map(function (block, i) {
            switch (block?.__typename) {
              case "PagesBlocksHeadline":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <Headline data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksBanner":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <Banner data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksTextAndButton":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <TextAndButton data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksPressLogos":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <PressLogos data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksTextAndImage":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <TextAndImage data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksContactForm":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <ContactForm data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksSolutions":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <SolutionsSection data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksCollabProjects":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <CollabProjectScrollBar data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksJobPosts":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <JobPosts data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksKeyWords":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <KeyWords data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksMap":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <Map data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksCaseStudies":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <CaseStudiesSection data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksHeroSection":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <HeroSection data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksPaginationSection":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <PaginationSection data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksOurProcess":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <OurProcessSection data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksUseCases":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <UseCaseSection data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksTestimonials":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <Testimonials data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksAboutUsHero":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <AboutUsHero data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksQuoteImage":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <QuoteImage data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksTextBlock":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <TextBlock data={block} />
                  </React.Fragment>
                );
              case "PagesBlocksNewsLetterBlock":
                return (
                  <React.Fragment key={i + block.__typename}>
                    <NewsLetterBlock data={block} />
                  </React.Fragment>
                );
              default:
                return null;
            }
          })
        : null}
    </>
  );
};
