import { Box, Image, Stack, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import { ServiceToIndex } from "../../../consts/serviceToIndexMapping";

export interface SolutionsServiceProps {
  imageURL?: Maybe<string> | undefined;
  imageAlt?: Maybe<string> | undefined;
  descriptionText?: Maybe<string> | undefined;
}

function SolutionsService(data: SolutionsServiceProps) {
  const router = useRouter();

  const handleImageClick = (imgAlt: string) => {
    const i = ServiceToIndex[imgAlt]; // the index for the scrollable service component in /PaginationBlock.tsx

    router.push({
      pathname: "/services",
      query: {
        i,
      },
    });
  };

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      spacing={{ base: "1em", lg: "2em" }}
    >
      {data.imageURL && (
        <Box minHeight="170px" minWidth="170px">
          <Image
            src={data.imageURL}
            alt={data.imageAlt ?? ""}
            height="170px"
            width="170px"
            objectFit="cover"
            onClick={() => handleImageClick(data.imageAlt as string)}
            _hover={{ cursor: "pointer" }}
          />
        </Box>
      )}
      <Text fontSize={{ base: "sm", xl: "md" }}>{data.descriptionText}</Text>
    </Stack>
  );
}

export default SolutionsService;
