import { Container, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import MonoButton from "../../common/MonoButton";

interface HeroProps {
  data: {
    backgroundPattern?: Maybe<string>;
    heading?: Maybe<string>;
    subHeading?: Maybe<string>;
    description?: Maybe<string>;
    buttonText?: Maybe<string>;
    buttonLink?: Maybe<string>;
  };
}

function HeroSection({ data }: HeroProps) {
  return (
    <Flex
      backgroundImage={`/patterns/${data?.backgroundPattern}.svg`}
      width="100%"
    >
      <Container>
        <VStack
          alignItems="flex-start"
          spacing="6"
          padding={{ base: "14 7", md: "20 12", lg: "120px 0px" }}
        >
          <Heading as="h1">{data.heading}</Heading>
          <Text mt={{ base: "6", lg: "8" }} fontSize="md" fontWeight="900">
            {data.subHeading}
          </Text>
          <Text fontSize="md">{data.description}</Text>
          <MonoButton text={data.buttonText} link={data.buttonLink} arrow />
        </VStack>
      </Container>
    </Flex>
  );
}

export default HeroSection;
