import React from "react";
import { Heading, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { Maybe } from "../../.tina/__generated__/types";

import dynamic from "next/dynamic";

// Konva cant load on the server so we need to disable SSR
const HeadlineBackground = dynamic(() => import("./HeadlineBackground"), {
  ssr: false,
});

export interface HeadlineProps {
  data: {
    line1?: Maybe<string> | undefined;
    line2?: Maybe<string> | undefined;
    subtitle?: Maybe<string> | undefined;
  };
}

function Headline({ data }: HeadlineProps) {
  const textColor = useColorModeValue("black", "white");

  return (
    <>
      <VStack
        alignItems="center"
        justifyContent="center"
        minHeight="400px"
        height={{ base: "95vh", lg: "calc(100vh + -3.8em)" }}
        maxWidth="100vw"
        overflow="hidden"
        backgroundSize={{ base: "cover", md: "initial" }}
        spacing="20"
        textStyle="bold"
        top="0"
        width="100%"
        color={textColor}
      >
        <HeadlineBackground />

        <VStack zIndex="99">
          <Heading
            as="h1"
            fontSize={{ base: "4xl", md: "5xl", lg: "6xl", xl: "7xl" }}
            textAlign="center"
          >
            <Text>{data.line1}</Text>
            <Text>{data.line2}</Text>
          </Heading>
        </VStack>
        <Text
          fontSize={{ base: "2xl", md: "3xl", lg: "4xl", xl: "6xl" }}
          zIndex="99"
        >
          {data.subtitle}
        </Text>
      </VStack>
    </>
  );
}

export default Headline;
