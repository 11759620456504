import { Flex, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";

export interface PaginationBlockProps {
  title?: Maybe<string>;
  description?: Maybe<string>;
  backgroundColour?: Maybe<string>;
  iconImgUrl?: Maybe<string>;
  iconImgAlt?: Maybe<string>;
}

function PaginationBlock(data: PaginationBlockProps) {
  return (
    <Flex
      backgroundColor={data.backgroundColour ?? undefined}
      w="100%"
      minH={{ base: "fit-content", lg: "420px" }}
      maxH={{ base: "fit-content", lg: "420px" }}
      h={{ base: "fit-content", lg: "420px" }}
    >
      <VStack
        spacing="4"
        alignItems="flex-start"
        padding={{ base: "6", lg: "8" }}
      >
        {data.iconImgUrl && (
          <Image
            height="78px"
            width="78px"
            src={data.iconImgUrl}
            alt={data.iconImgAlt ?? ""}
          />
        )}
        <Heading fontSize="lg">{data.title}</Heading>
        <Text fontSize="md" letterSpacing="0px">
          {data.description}
        </Text>
      </VStack>
    </Flex>
  );
}

export default PaginationBlock;
