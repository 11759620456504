import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import {
  Box,
  Container,
  Flex,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import SolutionsDescription, {
  SolutionsDescriptionProps,
} from "./SolutionsDescription";
import SolutionsService, { SolutionsServiceProps } from "./SolutionsService";

interface SolutionSectionProps {
  data: {
    solutionsDescription?: Maybe<SolutionsDescriptionProps> | undefined;
    solutionsService1?: Maybe<SolutionsServiceProps> | undefined;
    solutionsService2?: Maybe<SolutionsServiceProps> | undefined;
  };
}

function SolutionsSection({ data }: SolutionSectionProps) {
  const bgColor = useColorModeValue("#EDEDED", "gray.600");
  return (
    <Box bgColor={bgColor}>
      <Container>
        <Stack
          direction={{ base: "column", lg: "row" }}
          spacing="12"
          alignItems="center"
          justifyContent="center"
        >
          <Flex w="100%">
            <SolutionsDescription {...data.solutionsDescription} />
          </Flex>
          <Stack direction="column" w="100%" spacing="8">
            <SolutionsService {...data.solutionsService1} />
            <SolutionsService {...data.solutionsService2} />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default SolutionsSection;
