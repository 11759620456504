import {
  Flex,
  Heading,
  Button,
  Container,
  HStack,
  VStack,
  Text,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import PaginationBlock, { PaginationBlockProps } from "./PaginationBlock";
import { VscArrowRight, VscArrowLeft } from "react-icons/vsc";
import { useRouter } from "next/router";

interface SolutionButtonProps {
  title: string;
  active: boolean;
  onClick: () => void;
}

const SolutionButton = ({ title, active, onClick }: SolutionButtonProps) => {
  return (
    <Flex
      onClick={onClick}
      bg={active ? "#31FFD4" : "none"}
      _hover={{ background: "#31FFD4", color: "#000" }}
      color={active ? "#000" : "#b9bec7"}
      alignItems="flex-start"
      p="2"
      cursor="pointer"
      maxW="24rem"
    >
      <Text
        fontSize="lg"
        fontFamily="GT America Expanded Black"
        textAlign="left"
      >
        {title}
      </Text>
    </Flex>
  );
};

interface PaginationSectProps {
  data?: {
    sectionHeading?: Maybe<string> | undefined;
    items?: Maybe<Maybe<PaginationBlockProps>[]>;
  };
}

function PaginationSection({ data }: PaginationSectProps) {
  const router = useRouter();

  const [activeSolutionIndex, setActiveSolutionIndex] = useState(0);

  const [currentSolutionDisplayed, setCurrentSolutionDisplayed] =
    useState<Maybe<PaginationBlockProps>>();

  useEffect(() => {
    const solutionsLength = data?.items?.length!; // the amount of solutions items
    const currentSolution = data?.items?.find(
      (_, index) => index === activeSolutionIndex % solutionsLength
    );
    setCurrentSolutionDisplayed(currentSolution);
  }, [activeSolutionIndex, data?.items]);

  const incrementIndexCounter = () => {
    setActiveSolutionIndex((curr) => (curr + 1) % data?.items?.length!);
  };

  const decrementIndexCounter = () => {
    setActiveSolutionIndex(
      (curr) => (curr - 1 + data?.items?.length!) % data?.items?.length!
    );
  };

  useEffect(() => {
    if (router.query.i) {
      const indexNum = parseInt(router.query.i as string);
      setActiveSolutionIndex(indexNum);
      window.history.replaceState({}, "services", "/services");
      router.push("#our-services");
      window.history.replaceState({}, "services", "/services");
    }
  }, [router.query.i]);

  return (
    <Container id="our-services">
      <VStack alignItems="flex-start" spacing="12" w="100%">
        <Heading as="h3">{data?.sectionHeading}</Heading>
        <HStack
          justifyContent="space-between"
          w="100%"
          spacing="10"
          alignItems="flex-start"
        >
          <VStack alignItems="flex-start" width={{ base: "100%", lg: "70%" }}>
            {data?.items?.map((solution, index) => {
              return (
                <>
                  <SolutionButton
                    key={`${solution?.title}-${index}`}
                    title={solution?.title ?? ""}
                    onClick={() => setActiveSolutionIndex(index)}
                    active={index === activeSolutionIndex}
                  />
                  {index === activeSolutionIndex && (
                    <Box display={{ md: "none" }}>
                      <PaginationBlock {...currentSolutionDisplayed} />
                    </Box>
                  )}
                </>
              );
            })}
          </VStack>

          <VStack
            spacing="4"
            w="100%"
            flexGrow={1}
            dir="column-reverse"
            display={{ base: "none", md: "flex" }}
          >
            <PaginationBlock {...currentSolutionDisplayed} />
            <HStack
              justify="flex-end"
              alignItems="flex-start"
              w="100%"
              spacing="4"
            >
              <Button
                variant="outline"
                borderColor="black"
                onClick={() => decrementIndexCounter()}
              >
                <VscArrowLeft size="15px" />
              </Button>
              <Button
                variant="outline"
                borderColor="black"
                onClick={() => incrementIndexCounter()}
              >
                <VscArrowRight size="15px" />
              </Button>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Container>
  );
}

export default PaginationSection;
