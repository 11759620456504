import {
  Container,
  Heading,
  HStack,
  VStack,
  Text,
  Box,
  Image,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import { Maybe } from "../../.tina/__generated__/types";
import { textStyles } from "../../theme/textStyles";

interface QuoteImageProps {
  data: {
    heading?: Maybe<string>;
    mainText?: Maybe<string>;
    image?: Maybe<string>;
    imageAlt?: Maybe<string>;
    imageLabel?: Maybe<string>;
    imageBackgroundColor?: Maybe<string>;
    quote?: Maybe<string>;
    quoteBackgroundColor?: Maybe<string>;
  };
}

function QuoteImage({
  data: {
    heading,
    mainText,
    imageBackgroundColor,
    imageLabel,
    imageAlt,
    image,
    quote,
    quoteBackgroundColor,
  },
}: QuoteImageProps) {
  const belowMd = useBreakpointValue({ base: true, md: false });

  return (
    <Container>
      <Stack
        direction={{ base: "column", xl: "row" }}
        spacing={{ base: "6", md: "12" }}
      >
        <Stack
          direction={{ base: "column", md: "row" }}
          w={{ base: "100%", xl: "60%" }}
          spacing={{ base: "6", md: undefined }}
          alignItems={{ md: "center" }}
        >
          <Box
            background={`linear-gradient(white, ${imageBackgroundColor})`}
            css={
              !belowMd &&
              css`
                margin-right: -2rem !important;
              `
            }
            w={{ base: "100%", md: "60%" }}
          >
            <Image src={image ?? ""} alt={imageAlt ?? ""} />

            <Box
              bgColor={imageBackgroundColor ?? ""}
              p="2"
              w="fit-content"
              h="40px"
              position="relative"
              top="-40px"
              css={css`
                margin-bottom: -40px !important;
              `}
            >
              <Text {...textStyles.mono}>{imageLabel}</Text>
            </Box>
          </Box>
          <Box
            maxW={{ md: "20rem" }}
            bgColor={quoteBackgroundColor ?? ""}
            px="3"
            py="4"
            css={
              !belowMd &&
              css`
                margin-left: -2rem !important;
              `
            }
            w={{ base: "100%", md: "60%" }}
          >
            <Text fontSize="sm">{quote}</Text>
          </Box>
        </Stack>
        <VStack
          alignItems="flex-start"
          w={{ base: "100%", xl: "40%" }}
          spacing={{ base: "3", md: "6" }}
        >
          <Heading as="h3">{heading}</Heading>
          <Text>{mainText}</Text>
        </VStack>
      </Stack>
    </Container>
  );
}

export default QuoteImage;
