import {
  Box,
  Button,
  Container,
  HStack,
  Stack,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { VscArrowRight } from "react-icons/vsc";
import { Maybe } from "../../.tina/__generated__/types";
import { textStyles } from "../../theme/textStyles";

interface PressLogosProps {
  data: {
    items?: Maybe<
      | Maybe<{
          image?: Maybe<string>;
          alt?: Maybe<string>;
          link?: Maybe<string>;
          height?: Maybe<number>;
          width?: Maybe<number>;
        }>[]
    >;
    buttonLink?: Maybe<string>;
    buttonText?: Maybe<string>;
    heading?: Maybe<string>;
    backgroundColor?: Maybe<string>;
  };
}

function PressLogos({
  data: { items, buttonLink, buttonText, heading, backgroundColor },
}: PressLogosProps) {
  const { colorMode } = useColorMode();

  return (
    <Box w="100%" bgColor={backgroundColor ?? undefined}>
      <Container maxWidth="container.lg">
        {heading && (
          <Box pt="10">
            <Text {...textStyles.bold} textAlign="center" fontSize="4xl">
              {heading}
            </Text>
          </Box>
        )}
        <VStack>
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={{ base: 0, md: 20 }}
            flexWrap="wrap"
            justifyContent="space-evenly"
            alignItems="center"
            width="100%"
          >
            {items?.map((data, i) => (
              <a href={data?.link ?? ""} key={`${data?.link}-${i}`}>
                {data?.image && data?.height && data?.width && (
                  <Box filter={`grayscale(1)${colorMode === "dark" ? " invert(1)" : ""}`}>
                    <Image
                      src={data?.image as string}
                      alt={data?.alt ?? ""}
                      width={data.width}
                      height={data.height}
                      objectFit="contain"
                    />
                  </Box>
                )}
              </a>
            ))}
          </Stack>
          {buttonLink && buttonText && (
            <Box pb="50px">
              <Link href={buttonLink} passHref>
                <Button variant="outline" borderColor="black">
                  <Text textStyle="mono" mr="1em">
                    See all press
                  </Text>
                  <VscArrowRight size="23px" />
                </Button>
              </Link>
            </Box>
          )}
        </VStack>
      </Container>
    </Box>
  );
}

export default PressLogos;
