import { Container, HStack, VStack, Heading, Flex } from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import { Maybe } from "../../.tina/__generated__/types";
import Testimonial from "./Testimonial";

interface TestimonialsProps {
  data: {
    heading?: Maybe<string>;
    items?: Maybe<
      Maybe<{
        backgroundColour?: Maybe<string>;
        logo?: Maybe<string>;
        clientName?: Maybe<string>;
        clientLocation?: Maybe<string>;
        text?: Maybe<string>;
      }>[]
    >;
  };
}

function Testimonials({ data: { items, heading } }: TestimonialsProps) {
  return (
    <Container>
      <VStack spacing="8">
        <HStack w="100%" justify={{ base: "start", lg: "center" }}>
          <Heading as="h2">{heading}</Heading>
        </HStack>

        <Flex
          flexWrap="wrap"
          justifyContent="center"
          css={css`
            gap: 1rem;
          `}
        >
          {items?.map(
            (item) =>
              item && (
                <Testimonial
                  key={`${item.clientName}`}
                  name={item.clientName}
                  backgroundColor={item.backgroundColour}
                  logo={item.logo}
                  location={item.clientLocation}
                  text={item.text}
                />
              )
          )}
        </Flex>
      </VStack>
    </Container>
  );
}

export default Testimonials;
