import { Box, Container, Flex, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import { Maybe } from "../../../.tina/__generated__/types";
import OurProcessItem from "./OurProcessItem";
import OurProcessText, { OurProcessTextProps } from "./OurProcessText";
import { VscArrowRight } from "react-icons/vsc";
import { useRouter } from "next/router";
import MonoButton from "../../common/MonoButton";

interface OurProcessSectionProps {
  data?: {
    text?: Maybe<OurProcessTextProps>;
    processItems?: Maybe<Maybe<string>[]>;
  };
}

function OurProcessSection({ data }: OurProcessSectionProps) {
  return (
    <Box bgColor="#EDEDED">
      <Container>
        <Flex justifyContent="center">
          <Stack
            direction={{ base: "column", lg: "row" }}
            justifyContent="space-between"
            width="100%"
            spacing="6"
          >
            <Flex width={{ base: "100%", lg: "50%" }}>
              <OurProcessText {...data?.text} />
            </Flex>
            <VStack
              width={{ base: "100%", lg: "50%" }}
              mt={{ base: "30px", lg: "0" }}
              alignItems="stretch"
              spacing="6"
            >
              {data?.processItems?.map((text, index) => (
                <OurProcessItem text={text} key={`${text}-${index}`} />
              ))}
              <Box display={{ lg: "none" }}>
                <MonoButton
                  text={data?.text?.buttonText}
                  link={data?.text?.buttonLink}
                  arrow
                />
              </Box>
            </VStack>
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
}

export default OurProcessSection;
