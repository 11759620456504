import { Box, Button, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { useRouter } from "next/router";
import React from "react";
import { VscArrowRight } from "react-icons/vsc";
import { Maybe } from "../../../.tina/__generated__/types";
import MonoButton from "../../common/MonoButton";
import UseCaseTile, { UseCaseTileProps } from "./UseCaseTile";

interface UseCaseSectionProps {
  data: {
    sectionHeading?: Maybe<string>;
    sectionText?: Maybe<string>;
    useCaseTileItems?: Maybe<Maybe<UseCaseTileProps>[]>;
    buttonText?: Maybe<string>;
    buttonLink?: Maybe<string>;
  };
}

function UseCaseSection({ data }: UseCaseSectionProps) {
  const router = useRouter();
  return (
    <Container py="50px">
      <Flex bgColor="#fff" direction="column" maxW="1400px">
        <Flex direction="row">
          <Flex direction="column" width={{ lg: "80%" }}>
            <Heading fontSize={{ base: "22px", lg: "35px" }}>
              {data.sectionHeading}
            </Heading>
            <Text fontSize="md" mt={{ base: "25px", lg: "35px" }}>
              {data.sectionText}
            </Text>
          </Flex>
          <Flex
            direction="column"
            justify="flex-start"
            align="flex-end"
            width={{ lg: "20%" }}
            display={{ base: "none", lg: "flex" }}
          >
            <MonoButton text={data.buttonText} link={data.buttonLink} arrow />
          </Flex>
        </Flex>
        <Flex
          direction={{ base: "column", lg: "row" }}
          mt={{ base: "20px", lg: "45px" }}
          justify="space-between"
          flexWrap="wrap"
          css={css`
            row-gap: 80px;
          `}
        >
          {data.useCaseTileItems?.map((tile) => (
            <UseCaseTile {...tile} key={tile?.tileHeading} />
          ))}
        </Flex>
        <Box
          display={{ base: "flex", lg: "none" }}
          justifyContent="center"
          mt="20px"
        >
          <MonoButton text={data.buttonText} link={data.buttonLink} arrow />
        </Box>
      </Flex>
    </Container>
  );
}

export default UseCaseSection;
